div.furniture-brand-wrapper {
  position: absolute;
  width: 27%;
  left: 73%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 32px;
  z-index: 101;
  img {
    width: 128px;
    height: 64px;
    box-sizing: content-box;
    object-fit: contain;
  }
  h1 {
    font-size: 24px;
    margin: 0;
    margin-bottom: 4px;
    margin-top: 24px;
    font-weight: 400;
    text-align: center;
    color: $silver;
    opacity: .8;
  }
  h2 {
    font-size: 18px;
    text-align: center;
    margin: 0;
    margin-bottom: 12px;
    color: $silver;
    font-weight: 400;
    opacity: .8;
  }
}