div.viewer-loader-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: $silver;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0;
  visibility: hidden;
  transition: 320ms;
  transition-delay: 320ms;
  z-index: 2048;
  &.visible {
    transition-delay: 0;
    opacity: 1;
    visibility: visible;
  }
  h1 {
    font-size: 18px;
    font-weight: 500;
    color: $carbon;
    margin: 8px;
    &:first-of-type {
      margin-top: 32px;
    }
  }
  img.loader {
    display: block;
    width: 48px;
    height: 48px;
    transition: 320ms;
    transition-delay: 320ms;
    animation: loading 640ms linear infinite;
    filter: brightness(0);
  }
}

@keyframes loading {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}