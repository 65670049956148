$active: #068BFA;
$green: #00BB74;
$coral: #ed164c;
$blush: #f3bb8a;
$pinkishGrey: #e2d7c5;
$white: #ffffff;
$silver: #f0f1f1;
$lightGrey: #dfe1e1;
$grey: #c6c8ca;
$darkGrey: #a8a9aa;
$granite: #75787b;

$carbon: #4c4c4e;
$carbon: #2E3442;

$granite: #9B9BA2;

$border: transparentize(#9B9BA2, 0.76);

$moon: #d3e7e9;
$blueish: #cad5d1;
$silverTwo: #acbfb8;
$teal: #55939f;
$slate: #4b6c72;
$black: #000;
// $opaqueBlack = 'rgba(0, 0, : 4);
// $transparent = 'rgba(0, 0:  0);