div.viewer-wrapper {
  height: 100vh;
  overflow: auto;
  canvas {
    // pointer-events: none;
    display: none;
    width: 100%
  }
  div.image-wrapper {
    height: 100vh;
    img:not(.brand):not(.loader), canvas.image {
      height: 100%;
      display: block;
      // display: inline-block!important;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 1;
      // transition: 460ms;
      transition-timing-function: linear;
      &.overlay {
        animation: appear linear 420ms forwards;
        animation-delay: 150ms;
        opacity: 0;
      }
      &.image-old {
        opacity: 1;
        animation: disappear linear 420ms forwards;
        animation-delay: 450ms;
      }
    }
  }
  position: relative;
  z-index: 128;
  div.viewer-controller {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    padding: 12px;
  }
}

@keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes disappear {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes loading {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


div.viewer-loading-overlay {
  z-index: 1024;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 76%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 320ms;
  opacity: 1;
  &.hidden {
    opacity: 0;
  }
  img {
    width: 64px;
    height: 64px;
    animation: loading 640ms linear infinite;
  }
}
div.arrows-wrapper {
  z-index: 1024;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 76%;
  display: flex;
  justify-content: center;
  transition: 320ms;
  opacity: 1;
  div.arrow {
    flex: 1;
    padding: 64px;
    display: flex;
    align-items: center;
    cursor: pointer;
    opacity: 0.1;
    transition: 320ms;
    &:hover {
      opacity: 0.8;
    }
    &:last-child {
      justify-content: flex-end;
      img {
        transform: scaleX(-1) rotate(-90deg);
      }
    }
    img {
      filter: brightness(10000);
      width: 48px;
      height: 48px;
      transform: rotate(-90deg);
    }
  }
}