div.attributes-wrapper {
  &.collapsed {
    transform: translateX(100%) translateZ(0);
    opacity: 0;
  }
  // width: calc(4 * (52px + 16px) + 16px + 32px + 2px);
  width: 27%;
  height: 100vh;
  background-color: transparentize(rgb(255, 254, 254), 0.025);
  // border-top-left-radius: 5px;
  // border-bottom-left-radius: 5px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 32px 32px rgba(0, 0, 0, 0.04), 0 2px 64px 64px rgba(0, 0, 0, 0.04);
  overflow: auto;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  transition: 320ms;
  z-index: 1204;
  &::-webkit-scrollbar {
    display: none;
  }
  div.furniture-title {
    h1 {
      font-size: 24px;
      margin: 0;
      font-weight: 400;
      text-align: center;
      color: $carbon;
    }
    h2 {
      font-size: 18px;
      text-align: center;
      margin: 0;
      margin-bottom: 12px;
      color: $granite;
      font-weight: 400;
    }
    position: relative;
    margin-top: calc(12px + 16px);
    margin-bottom: 32px;
  }

  div.units-wrapper {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    box-sizing: border-box;
    padding: 8px;
    width: calc(4 * (52px + 16px) + 16px);
    width: calc(100% - 16px);
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-auto-rows: 80px;
    width: 100%;
  }
  div.part-list {
    display: flex;
    flex: 1;
    flex-direction: column;
  }
}

div.material-wrapper {
  box-sizing: content-box;
  width: calc(52px + 8px);

  margin-bottom: 0px;
  // margin: 6px;
  // margin: 8px;
  cursor: pointer;
  &.active {
    div.img-wrapper {
      position: relative;
      display: block;
      &::after {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        content: '';
        display: block;
        border: 2px solid $carbon;
        border-radius: 5px;
      }
      div.active-dot {
        position: absolute;
        top: 2px;
        right: 2px;
        width: 16px;
        height: 16px;
        border-radius: 500px;
        transform: translate(50%, -50%);
        background-color: $coral;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 12;
        img {
          width: 7px;
          height: 5.78px;
          transform: scale(1.2);
        }
      }
    }
  }

  div.img-wrapper {
    width: 52px;
    height: 52px;
    border-radius: 5px;
    margin: 4px;
    margin-bottom: 6px;
    img {
      border-radius: 5px;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  span {
    display: block;
    font-size: 14px;
    font-weight: 400;
    color: $carbon;
    margin-top: -4px;
    margin-bottom: 4px;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

div.options-wrapper {
  display: flex;
  flex-direction: column;
  padding: 8px 0;
  div.option {
    padding: 8px 16px;
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    span {
      font-size: 16px;
      font-weight: 500;
      color: $carbon;
    }
    div.radio {
      width: 16px;
      height: 16px;
      border-radius: 500px;
      border: 1px solid $granite;
      margin-right: 16px;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &.active {
      div.radio::after {
        display: block;
        content: '';
        width: 10px;
        height: 10px;
        border-radius: 500px;
        background-color: $granite;
      }
    }
  }
}

div.part-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  // background: white;
  border-radius: 4px;
  // box-shadow: 0 2px 8px 8px rgba(0, 0, 0, 0.01), 0 2px 16px 16px rgba(0, 0, 0, 0.01);
  margin-bottom: 16px;
  overflow: hidden;
  border: 1px solid darken($border, 30%);
  div.dropdown-wrapper {
    transition: 320ms;
  }

  span.group-label {
    user-select: none;
    font-size: 16px;
    font-weight: 400;
    padding: 0px;
    padding-left: 16px;
    color: $granite;
    display: block;
    margin-top: 12px;
  }
  div.part-title {
    height: 56px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    user-select: none;
    padding-left: 16px;
    position: relative;
    transition: 240ms;
    border-bottom: 1px solid transparent;
    &.active {
      border-bottom: 1px solid $border;
      img.dropdown {
        transform: translateY(-50%) rotate(-180deg);
      }
    }
    &:hover {
      // background-color: darken(white, 2%);
    }
    &:active {
      // background-color: darken(white, 5%);
    }
    span {
      display: block;
      font-size: 14px;
      margin: 2px 0;
      &.name {
        margin-right: 12px;
        color: $granite;
        font-weight: 400;
        text-transform: uppercase;
      }
      &.active-unit {
        color: $carbon;
        font-weight: 500;
        margin-top: 2px;
      }
    }
    img.dropdown {
      width: 12px;
      height: 6px;
      position: absolute;
      top: 50%;
      right: 16px;
      transform: translateY(-50%);
      transition: 240ms;
    }
  }
}

div.sidebar-close-wrapper {
  height: 42px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 16px;
  cursor: pointer;
  position: absolute;
  right: -16px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1204;

  img {
    transform: rotate(-90deg);
    width: 24px;
  }
}

div.sidebar-handler-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 73%;
  transform: translateX(-100%);
  width: 64px;
  justify-content: center;
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  padding-right: 16px;
  opacity: 0.4;
  cursor: pointer;
  z-index: 1204;
  &.collapsed {
    left: 100%;
    div {
      transform: rotate(12deg) translateY(1.5px);
      &:last-child {
        transform: rotate(-12deg) translateY(-1.5px);
      }
    }
  }
  transition: 320ms left;
  div {
    width: 4px;
    height: 32px;
    border-radius: 32px;
    background-color: $silver;
    transform-origin: bottom;
    transform: rotate(-12deg) translateY(1.5px);
    transition: 320ms transform;
    &:last-child {
      transform-origin: top;
      transform: rotate(12deg) translateY(-1.5px);
    }
  }
}
