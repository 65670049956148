div.types-outer-wrapper {
  user-select: none;
  position: relative;
  div.types-wrapper {
    padding: 16px;
    margin-bottom: 4px;
    margin-top: -4px;
    display: flex;
    align-items: center;
    cursor: pointer;
    span.label {
      font-size: 16px;
      font-weight: 400;
      color: $carbon;
      text-align: center;
      text-transform: capitalize;
    }
    img.dropdown {
      width: 12px;
      height: 6px;
      margin-left: 12px;
      transition: 240ms;
    }
    &.visible {
      img {
        transform: rotate(-180deg);
      }
    }
  }
  div.types-dropdown-wrapper {
    position: absolute;
    top: 100%;
    padding: 8px 0;
    left: 4px;
    right: 4px;
    background-color: white;
    border: 1px solid $border;
    border-radius: 5px;
    box-shadow: 0 2px 32px 32px rgba(0, 0, 0, 0.02);
    z-index: 32;
    transition: 240ms;
    opacity: 1;
    visibility: visible;
    transform: translateY(-12px);
    &.hidden {
      opacity: 0;
      visibility: hidden;
      transform: translateY(-10px);
    }
    div.select-option-wrapper {
      padding: 4px 12px;
      cursor: pointer;
      span {
        font-size: 16px;
        font-weight: 400;
        color: $granite;
        margin-top: -4px;
        margin-bottom: 4px;
        text-align: center;
        text-transform: capitalize;
        transition: 240ms;
      }
      &.active {
        span {
          text-decoration: underline;
          color: $carbon;
        }
      }
      &:hover span {
        color: $carbon;
      }
    }
  }
}