div.furniture-wrapper {
  position: relative;
  margin: auto;
  display: flex;
  width: calc(100vh / 3 * 4);
  overflow: hidden;
  & > div {
    flex: 1;
  }
  div.furniture {
    flex: 1;
    background-color: black;
  }
}