// @import url("https://use.typekit.net/vre6zsy.css");
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500&display=swap');
@import 'variables', 'furniture', 'viewer', 'attributes', 'typeselector', 'loader', 'brand';


html, body {
  width: 100vw;
  height: 100vh;
  padding: 0;
  margin: 0;
  background-color: $silver;
  *{
    box-sizing: border-box;
    font-family: sans-serif;
    font-family: 'Raleway', sans-serif !important;
  }
}

body {
  display: flex;
  div.screen-wrapper {
    flex: 1;
    display: flex;
  }
}

div#root {
  display: flex;
  flex: 1;
}

div.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(black, .4);
  z-index: 2048;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(16px);
  div.backdrop {
    width: 100%;
    height: 100%;
  }
  button {
    position: absolute;
    top: 8px;
    left: 0;
    color: white;
    background:none;
    border: none;
    padding: 24px;
    font-size: 18px;
    z-index: 5;
    cursor: pointer;
    outline: none;
  }
  div.modal {
    width: 800px;
    height: 800px;
    background: white;
    border-radius: 4px;
    overflow: hidden;
    position: absolute;
    background: $granite;
    div.loader-wrapper {
      width: 100%;
      height: 100%;
      position: absolute;
      display: flex;
      justify-content:center;
      align-items: center;
      opacity: 1;
      transition: 320ms;
      &.hidden {
        opacity: 0;
      }
      img.loader {
        width: 64px;
        height: 64px;
        animation: loading 640ms linear infinite;
      }
    }
    img.unit {
      width: 100%;
      height: 100%;
      object-fit: cover;
      opacity: 1;
      transition: 320ms;
      &.hidden {
        opacity: 0;
      }
    }
    h1 {
      position: absolute;
      top: 24px;
      left: 0;
      right: 0;
      color: white;
      text-align: center;
      pointer-events: none;
      margin: 0;
      padding: 0;
      font-weight: 500;
      font-size: 24px;
      opacity: 0.72;
      z-index: 500;
    }
    
  }
}

@keyframes loading {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
